<template>
  <section fluid>
    <!-- title -->
    <p class="text-h6 font-museo-sans mb-0 secondary--text">
      Agregar necesidades
    </p>
    <p class="text-body-2 font-museo-sans primary--text">
      Ejercicio {{ anioFiscal }}
    </p>

    <!-- filtros -->
    <v-row align="center" class="mt-4">
      <template v-if="haveRole('ROLE_UNIDAD_CONSOLIDADORA')">
        <v-col cols="12" sm="8" class="d-flex flex-column flex-sm-row">
          <div v-if="status_unidad_var" class="text-h6">
            <span v-text="'Unidad seleccionada: '" />
            <span class="secondary--text" v-text="unidad_select_var?.nombre" />
          </div>
          <v-autocomplete v-else label="Unidad solicitante" v-model="unidad_select_var"
            :items="ctl_unidad_solicitante_var" item-text="nombre" return-object outlined clearable hide-details />
          <!-- boton que selecciona la unidad -->
          <v-btn v-if="!status_unidad_var" :disabled="unidad_select_var == null"
            class="button-extra text-capitalize mt-4 mt-sm-3 ml-sm-8" color="secondary"
            @click="status_unidad_var = true">
            Seleccionar
          </v-btn>
        </v-col>
      </template>

      <v-col cols="12" sm="12" md="6" lg="6">
        <v-autocomplete label="OBS" :items="obsList" v-model="segmentoSeleccionado" outlined item-value="id"
          item-text="nombre" @change="getCifradosOBS" :disabled="validUnidad" :readonly="this.$route.params.idSolicitudNecesidad
              ? true
              : false || fondos.length > 0
            " hide-details />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <div class="d-flex justify-end">
          <div>
            <p class="text-h4 mb-0 text-center">
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(calculateMontoTotal || 0)
              }}
            </p>
            <p>Monto total solicitado</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea label="Información adicional" auto-grow rows="4" outlined clearable counter maxlength="500"
          v-model="detalle_var" :error-messages="detalleErrors" @blur="$v.detalle_var.$touch()" />
      </v-col>
    </v-row>

    <!-- action add -->
    <v-row class="my-4">
      <v-col cols="12" sm="3" md="2">
        <p class="text-body-2 font-museo-sans primary--text">
          Fuentes de financiamiento
        </p>
        <v-btn :disabled="validObsSelect" @click="addDialog = true" class="secondary text-no-style" block>
          Agregar fuente
        </v-btn>
      </v-col>
    </v-row>

    <!-- table -->
    <div>
      <v-data-table :headers="headers" :items="fondos">
        <template v-slot:[`item.monto`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.FondosSolicitudNecesidad?.monto || item.monto)
          }}
        </template>
        <template v-slot:[`item.codigo`]="{ item }">
          {{ item?.Fondo?.tipo_fondo === 1 ? 'Proyecto' : 'Votado' }} -
          {{ item.Fondo?.cifrado_presupuestario || item.codigo }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip right left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small @click="(deleteDialog = true), (detalle_seleccionado = item)">
                <v-icon color="secondary">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span> Eliminar </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <div class="my-10">
      <v-btn outlined color="secondary" @click="$router.back()"> Volver </v-btn>
      <v-btn :disabled="fondos.length == 0" @click="abrirModalConfirm()" class="mx-4" color="secondary white--text">
        {{ editable ? "Editar" : "Enviar" }}
      </v-btn>
    </div>

    <dialogoConfirmacion :show="deleteDialog"
      title="¿Desea eliminar la fuente de financiamiento agregada a la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles." btnConfirmar="Aceptar"
      @close="deleteDialog = false" @confirm="deleteDetail" />

    <dialogoConfirmacion :show="confirmDialog" :title="`¿Desea ${editable ? 'editar' : 'guardar'} los cambios?`"
      :message="`De confirmarse la siguiente acción, se guardarán los cambios y la solicitud será ${haveRole('ROLE_JEFE_UNIDAD')
          ? 'aprobada'
          : 'enviada al jefe para ser aprobada'
        }`" btnConfirmar="Aceptar" @close="confirmDialog = false" @confirm="enviarSolicitud" />

    <v-dialog persistent max-width="600" class="modal" v-model="addDialog">
      <v-card max-width="600">
        <v-btn @click="addDialog = false" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">Agregar financiamiento</p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete outlined label="Cifrado presupuestario" :items="cifradosList" item-value="id"
                  item-text="cifrado" v-model="fnto_unidad" return-object :error-messages="cifradoPresupuestarioErrors"
                  @blur="$v.fnto_unidad.$touch()" />
                <div class="mx-2 my-2">
                  <p>
                    {{ fnto_unidad?.tipo_fondo }}
                  </p>
                  <p v-for="ctr in fnto_unidad?.construccion">
                    {{ ctr?.codigo }} {{ ctr?.nombre }}
                  </p>
                </div>
                <div class="mx-2 pt-4 text-center">
                  <p class="text-h4 mb-0">
                    $
                    {{
                      Intl.NumberFormat("en-US", {
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(fnto_unidad?.monto_disponible || 0)
                    }}
                  </p>
                  <p>Monto disponible</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <money-text-field-component ref="ref_monto" label="Monto a utilizar" v-model="monto" outlined
                  :error-messages="montoErrors" @input="$v.monto.$touch()" />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-btn @click="addDialog = false" outlined color="secondary">Cancelar</v-btn>
              <v-btn @click="addFinanciamiento" color="secondary white--text" class="mx-4">Agregar</v-btn>
            </v-row>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import dialogoConfirmacion from "../../components/ConfirmationDialogComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";

import { mapState } from "vuex";
import { capitalize } from "lodash";

const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

// Monto mayor a fondos disponibles en fnto_unidad
const montoMayorADisponible = (value, attr) => {
  if (!value) return true;
  if (attr.fnto_unidad?.tipo_fondo !== "Votado") return true;
  // Redondear el monto_disponible a 2 decimales para evitar problemas de precisión
  const montoDisponible = parseFloat(attr.fnto_unidad?.monto_disponible).toFixed(2);
  return Number(value) <= Number(montoDisponible);
};

export default {
  name: "AddNecesidadesView",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
    MoneyTextFieldComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Código presupuestario",
        value: "codigo",
      },

      {
        text: "Monto($)",
        value: "monto",
        align: "right",
      },

      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    fondos: [],
    deleteDialog: false,
    addDialog: false,
    obsList: [],
    segmentoSeleccionado: null,
    cifradosList: [],
    solicitudNecesidad: {
      id_unidad: null,
      id_obs: null,
      fondos: [],
    },
    fnto_unidad: null,
    monto: null,
    detalle_seleccionado: null,
    editable: false,
    ctl_unidad_solicitante_var: null,
    unidad_select_var: null,
    status_unidad_var: false,
    empty_cifrado_var: true,
    confirmDialog: false,

    detalle_var: null,
  }),
  validations: {
    fnto_unidad: { required },
    monto: { required, priceDecimals, montoMayorADisponible },
    detalle_var: { txtArea },
  },
  computed: {
    ...mapState(["userInfo", "anioFiscal", "selectedUnidad"]),
    ...mapState({
      id_unidad_cmp: (state) => state?.selectedUnidad?.id,
    }),

    validUnidad() {
      if (this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")) {
        return !this.status_unidad_var;
      } else {
        return false;
      }
    },

    validObsSelect() {
      if (this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")) {
        return this.empty_cifrado_var && !this.segmentoSeleccionado;
      } else {
        return !this.segmentoSeleccionado;
      }
    },

    cifradoPresupuestarioErrors() {
      const errors = [];
      if (!this.$v.fnto_unidad.$dirty) return errors;
      !this.$v.fnto_unidad.required &&
        errors.push("El campo cifrado presupuestario es obligatorio");
      return errors;
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.monto.$dirty) return errors;
      !this.$v.monto.required && errors.push("El campo monto es obligatorio");
      !this.$v.monto.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      !this.$v.monto.montoMayorADisponible &&
        errors.push(
          "El monto a utilizar no puede ser mayor al monto disponible"
        );
      return errors;
    },
    detalleErrors() {
      const errors = [];
      if (!this.$v.detalle_var.$dirty) return errors;
      !this.$v.detalle_var.txtArea &&
        errors.push(
          "El campo solo admite letras, números y los siguientes caracteres especiales: ()/.,:;-"
        );
      return errors;
    },
    calculateMontoTotal() {
      if (this.fondos.length > 0) {
        return this.fondos.reduce(
          (a, b) =>
            Number(a) + Number(b.monto || b.FondosSolicitudNecesidad?.monto),
          0
        );
      }
    },
  },
  methods: {
    async getSolicitud() {
      const { status, data } = await this.services.PacServices.getSolicitudPAC(
        this.$route.params.idSolicitudNecesidad,
        {
          pagination: false,
        }
      );

      if (this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")) {
        // agregar a unidad_select_var solo el nombre e id
        this.unidad_select_var = {
          id: data?.Unidad?.id,
          nombre: data?.Unidad?.nombre,
        };

        this.status_unidad_var = true;
      }

      if (status == 200) {
        this.fondos = data.FinanciamientoUnidads ?? [];
        this.segmentoSeleccionado = data.Ob?.id;
        this.detalle_var = data.informacion_adicional;
      }
    },

    async getOBSSegmentos() {
      const { status, data } = await this.services.PacServices.getOBSSegmentos({
        pagination: false,
      });

      if (status == 200) {
        this.obsList = data.map((col) => ({
          id: col.id,
          nombre: `${col.codigo} - ${capitalize(col.nombre)}`,
        }));
      }
    },
    async getCifradosOBS() {
      if (this.segmentoSeleccionado) {
        const { status, data } = await this.services.PacServices.getCifradosOBS(
          this.segmentoSeleccionado,
          {
            tipo_fondo: "1,2",
            id_unidad: this.unidad_select_var?.id || undefined,
          }
        );
        if (status == 200) {
          if (data.length == 0) {
            this.empty_cifrado_var = true;
            this.temporalAlert({
              message:
                "La unidad seleccionada no tiene cifrados presupuestarios asociados",
              show: true,
              type: "warning",
            });
            this.status_unidad_var = false;
            this.segmentoSeleccionado = null;
          } else {
            // this.cifradosList = data;
            this.cifradosList = data.map((element) => ({
              ...element,
              cifrado: `${element.tipo_fondo} - ${element.cifrado}`,
            }));
            this.empty_cifrado_var = false;
          }
        }
      }
    },
    clearInfo() {
      this.fnto_unidad = null;
      this.monto = null;
      this.$refs.ref_monto.updateValue(null);
      this.$v.$reset();
    },
    async addFinanciamiento() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.editable) {

          const { status } =
            await this.services.PacServices.postSolicitudNecesidadFondo(
              this.$route.params.idSolicitudNecesidad,
              {
                id_financiamiento_unidad: this.fnto_unidad.id,
                monto: this.monto,
              }
            );

          if (status == 201) {
            this.temporalAlert({
              show: true,
              message: `Fondo agregado con éxito`,
              type: "success",
            });
            this.getSolicitud();
          }
        } else {
          const exist = this.fondos.find(
            (f) => f.id_financiamiento_unidad == this.fnto_unidad.id
          );
          if (exist) {
            this.temporalAlert({
              show: true,
              message: "El financiamiento ya se encuentra agregado",
              type: "warning",
            });
            return;
          }

          this.fondos.push({
            id_financiamiento_unidad: this.fnto_unidad.id,
            codigo: this.fnto_unidad.cifrado,
            monto: this.monto,
          });
          this.solicitudNecesidad.fondos.push({
            id_financiamiento_unidad: this.fnto_unidad.id,
            monto: this.monto,
          });
          this.temporalAlert({
            show: true,
            message: `Fondo agregado con éxito`,
            type: "success",
          });
        }

        this.addDialog = false;
      }
    },
    async enviarSolicitud() {
      if (this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")) {
        this.solicitudNecesidad.id_unidad = this.unidad_select_var.id;
      } else {
        this.solicitudNecesidad.id_unidad = this.selectedUnidad.id;
      }

      this.solicitudNecesidad.id_obs = this.segmentoSeleccionado;

      let response = null;
      if (this.editable) {

        response = await this.services.PacServices.putSolicitudNecesidad(
          this.$route.params.idSolicitudNecesidad,
          {
            ...this.solicitudNecesidad,
            detalle: this.detalle_var,
          }
        );

      } else {

        response = await this.services.PacServices.postSolicitudNecesidad({
          ...this.solicitudNecesidad,
          detalle: this.detalle_var,
        });

      }

      if (response.status == 201 || response.status == 200) {
        this.temporalAlert({
          show: true,
          message: `Solicitud ${this.editable
              ? "actualizada"
              : this.haveRole("ROLE_JEFE_UNIDAD")
                ? "aprobada"
                : "enviada"
            } con éxito`,
          type: "success",
        });
        if (this.editable) this.confirmDialog = false;
        this.$router.back();
      }
    },
    async deleteDetail() {
      if (this.editable) {

        const { status } =
          await this.services.PacServices.deleteSolicitudesFondo(
            this.detalle_seleccionado.FondosSolicitudNecesidad?.id
          );

        if (status == 200) {
          this.temporalAlert({
            show: true,
            message: `Fondo eliminado con éxito`,
            type: "success",
          });
          this.getSolicitud();
        }
      } else {
        let index = this.fondos.indexOf(this.detalle_seleccionado);
        this.fondos.splice(index, 1);
        this.solicitudNecesidad.fondos.splice(index, 1);
        this.temporalAlert({
          show: true,
          message: `Fondo eliminado con éxito`,
          type: "success",
        });
      }

      this.deleteDialog = false;
    },

    async FetchUnidadesSolicitantesFtn() {

      const { status, data } =
        await this.services.UnidadesServices.getUnidadesDependientes(
          this.id_unidad_cmp
        );
      if (status == 200) {
        this.ctl_unidad_solicitante_var = data;
      }

    },

    abrirModalConfirm() {
      this.confirmDialog = true;
    },
  },

  watch: {
    addDialog(val) {
      if (!val) {
        this.clearInfo();
      }
    },
  },

  async created() {
    if (this.haveRole("ROLE_UNIDAD_CONSOLIDADORA")) {
      this.FetchUnidadesSolicitantesFtn();
    }

    if (this.$route.params.idSolicitudNecesidad) {
      this.editable = true;
      await this.getSolicitud();
      this.getCifradosOBS();
    }
    this.getOBSSegmentos();
  },
};
</script>
<style scoped>
.txt-unidad {
  font-size: 1rem;
  color: #000;
}
</style>
